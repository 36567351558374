import React from "react";
import "./SubscribeButton.css";

class SubscribeButton extends React.Component {
  render() {
    const { url } = this.props;
    if (url) {
      return (
        <a className="subscribe-button icon-twitter" href={url} target="_blank">
          Tweet me!
        </a>
      );
    }
    return null;
  }
}

export default SubscribeButton;
